<template>
  <v-app id="app" :class="qrMode ? 'qr-mode' : 'non-qr-mode'">
    <v-main style="overflow-y: auto">
      <transition-page>
        <router-view :pages="pages"></router-view>
      </transition-page>
      <v-dialog
        v-if="dialog"
        v-model="dialog"
        persistent
        style="max-width: 100px"
      >
        <v-card id="dialog">
          <v-card-title
            class="headline dialog-title"
            :class="isMobile ? 'dialog-title-mobile' : ''"
            :style="`background-color: ${block.page.color};`"
          >
            <span v-html="block.title"></span>
            <v-spacer></v-spacer>
            <v-btn dark text @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text
            :class="isMobile ? 'dialog-text dialog-text-mobile' : 'dialog-text'"
          >
            <block-video
              v-if="block.type === 'video'"
              :block="block"
              style="max-width: 125vh !important"
            ></block-video>
            <block-pdf v-if="block.type === 'pdf'" :block="block"></block-pdf>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="showContact"
        v-model="showContact"
        persistent
        style="max-width: 1800px"
      >
        <v-card
          id="dialog"
          class="contact-dialog"
          style="max-width: 800px; width: 75vw"
        >
          <v-card-title
            class="headline dialog-title"
            :class="isMobile ? 'dialog-title-mobile' : ''"
            :style="`background-color: ${contactData.color};`"
          >
            <span v-html="contactData.title"></span>
            <v-spacer></v-spacer>
            <v-btn dark text @click="closeContact()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text
            :class="isMobile ? 'dialog-text dialog-text-mobile' : 'dialog-text'"
          >
            <contact :page="contactData" :submitted="contentDisabled"></contact>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-if="requestPopup" v-model="requestPopup" persistent>
        <v-card id="requestPopup">
          <v-card-title
            class="headline dialog-title"
            :class="isMobile ? 'dialog-title-mobile' : ''"
            :style="`background-color: ${requestPage.color};`"
          >
            <span>Contact J&amp;J</span>
            <v-spacer></v-spacer>
            <v-btn dark text @click="requestPopup = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text style="text-align: center">
            <p class="mt-5">Please select one option below:</p>
            <v-btn
              dark
              class="request-btn"
              :color="requestPage.color"
              @click="openPopup('https://www.jamie-system.com/event/11520')"
            >
              Request Medical Information
            </v-btn>
            <v-btn
              dark
              class="request-btn"
              :color="contactData.color"
              @click="openContactPopup()"
            >
              General Inquiries
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="slideshowPopup.open"
        v-model="slideshowPopup.open"
        persistent
      >
        <block-pdf
          :block="slideshowPopup.block"
          :fullscreen="true"
          style="height: 100vh !important; width: 100vw !important"
        ></block-pdf>
      </v-dialog>
      <v-dialog v-if="promptShown" v-model="promptShown" persistent>
        <v-card class="px-3 py-8">
          <v-card-text>
            <p style="text-align: center; font-size: 150%; margin-bottom: 20px">
              Are you still there?
            </p>
            <p style="text-align: center; font-size: 110%; font-weight: 300">
              {{ promptCountdown }} second{{ promptCountdown === 1 ? "" : "s" }}
            </p>
          </v-card-text>
          <v-card-actions style="justify-content: center; font-size: 150%">
            <v-btn @click="startScreenSaverCheck">Continue</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div v-if="screenSaver" id="screen-saver" @click="hideScreenSaver">
        <div class="ss-prompt-wrapper">
          <div class="ss-prompt">Touch anywhere to begin</div>
        </div>
        <screen-saver />
      </div>
      <div v-if="!init" id="screen-saver" @click="hideScreenSaver">
        <div class="init-prompt-wrapper">
          <div class="init-prompt" @click="start()">Click to begin</div>
          <div
            style="height: 10px; width: 10px; position: relative; visibility: hidden;"
          >
            <screen-saver />
          </div>
        </div>
      </div>
    </v-main>

    <div id="retina-footer" class="white--text">
      <div class="disclaimer2">
        Your use of information on this site is subject to the terms of our
        <a
          href="#"
          @click.prevent="
            extLink(
              'https://www.janssen.com/legal-notice',
              'legal.png',
              'Use your cell phone to scan this QR code to view Johnson & Johnson\'s legal notice.'
            )
          "
          >Legal Notice</a
        >. Please see our
        <a
          href="#"
          @click.prevent="
            extLink(
              'https://www.janssen.com/privacy-policy',
              'privacy.png',
              'Use your cell phone to scan this QR code to view Johnson & Johnson\'s privacy policy.'
            )
          "
          >Privacy Policy</a
        >. This site is published by Janssen Global Services, LLC, which is
        solely responsible for&nbsp;its&nbsp;contents.
        <br />
        &copy; Janssen Global Services, LLC. 2024 09/24 cp-320493v8
        <br />

        <!-- Janssen Global Services -->
        <v-spacer></v-spacer>
        <div>
          <v-btn
            text
            dark
            target="_blank"
            @click="
              extLink(
                'https://www.linkedin.com/company/jnjinnovativemedicine/',
                'linkedin.png',
                'Use your cell phone to scan this QR code to view Johnson & Johnson\'s LinkedIn profile'
              )
            "
          >
            <v-icon>mdi-linkedin</v-icon>
          </v-btn>
          <v-btn
            text
            dark
            target="_blank"
            @click="
              extLink(
                'https://www.linkedin.com/company/jnjinnovativemedicine/',
                'twitter.png',
                'Use your cell phone to scan this QR code to view Johnson & Johnson\'s X account'
              )
            "
          >
            <img src="images/twitter.svg" style="height: 24px;" />
          </v-btn>
          <v-btn
            text
            dark
            target="_blank"
            @click="
              extLink(
                'https://www.instagram.com/jnjinnovativemedicine/',
                'instagram.png',
                'Use your cell phone to scan this QR code to view Johnson & Johnson\'s Instagram account'
              )
            "
          >
            <v-icon>mdi-instagram</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <div class="hidden">
      <img src="/images/qr/legal.png" />
      <img src="/images/qr/privacy.png" />
      <img src="/images/qr/twitter.png" />
      <img src="/images/qr/linkedin.png" />
      <img src="/images/qr/instagram.png" />
    </div>

    <v-dialog v-model="showQr" max-width="400px">
      <v-card>
        <v-card-title class="headline">&nbsp;</v-card-title>
        <v-card-text>
          <div id="download-qr" v-if="showQr">
            <img :src="qrCode" alt="QR code" />
            <div v-html="qrText"></div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="showQr = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import $http from "axios";
const MAIL_URL = "https://ret-email.netlify.app/.netlify/functions/email";
// const MAIL_URL = "https://retina-email-staging.netlify.app/.netlify/functions/email";
//timer countdown
const LIMIT = 90;

export default {
  name: "App",
  components: {
    TransitionPage: () => import("@/components/TransitionPage.vue"),
    BlockVideo: () => import("@/components/blocks/BlockVideo.vue"),
    BlockPdf: () => import("@/components/blocks/BlockPdf.vue"),
    Contact: () => import("@/components/Contact.vue"),
    ScreenSaver: () => import("@/components/ScreenSaver.vue")
  },
  data: () => ({
    init: false,
    cheecking: false,
    playing: false,
    timerStart: 0,
    promptCountdown: 0,
    promptCountdownTimeout: null,
    pages: require("@/data.json"),
    screenSaver: false,
    dialog: false,
    showContact: false,
    contentDisabled: false,
    requestPopup: false,
    requestPage: null,
    block: null,
    isMobile: false,
    promptShown: false,
    slideshowPopup: {
      block: null,
      open: false
    },
    contactData: {
      id: "contact",
      title: "Contact J&amp;J",
      componentName: "Contact",
      color: "#eb1600",
      bgimage: "mission-banner.png",
      popup: "requestPopup",
      component: "contact"
    },
    qrMode: true,
    showQr: false,
    qrCode: "",
    qrText: ""
  }),
  methods: {
    checkScreenSaver() {
      if (this.init && this.checking && !this.playing) {
        const MS = new Date().valueOf() - this.timerStart;
        const SEC = Math.floor(MS / 1000);
        // console.log(`checkScreenSaver() - ${SEC} seconds`);
        // console.log({ SEC, LIMIT });
        if (SEC >= LIMIT) {
          this.showPrompt();
        } else {
          setTimeout(this.checkScreenSaver, 1000);
        }
      }
    },
    showPrompt() {
      this.promptShown = true;
      //rolloff countdown
      this.promptCountdown = 10;
      clearTimeout(this.promptCountdownTimeout);
      this.promptCountdownTimeout = setTimeout(this.updateCountdown, 1000);
    },
    updateCountdown() {
      if (this.promptShown) {
        this.promptCountdown--;
        if (this.promptCountdown === 0) {
          this.promptShown = false;
          this.showScreenSaverOrGoToMenu();
        } else {
          clearTimeout(this.promptCountdownTimeout);
          this.promptCountdownTimeout = setTimeout(this.updateCountdown, 1000);
        }
      }
    },
    showScreenSaverOrGoToMenu() {
      this.promptShown = false;
      // if (this.$route.name === "menu") {
      //   this.showScreenSaver();
      // } else {
      this.$hub.$emit("closePopup");
      // this.startScreenSaverCheck();
      // }
    },
    startScreenSaverCheck() {
      console.log("startScreenSaverCheck");
      this.promptShown = false;
      this.timerStart = new Date().valueOf();
      setTimeout(this.checkScreenSaver, 1000);
    },
    openContactPopup() {
      this.contentDisabled = false;
      this.requestPopup = false;
      this.showContact = true;
    },
    openPopup(url) {
      this.requestPopup = false;
      window.open(url, "_blank");
    },
    openRequestPopup() {
      this.openContactPopup();
    },
    openRequestPage() {
      this.requestPopup = false;
      this.$router.push({ name: "request" });
    },
    setWidth() {
      const NARROW = window.innerWidth < 768;
      const SHORT = window.innerHeight < 650;
      this.isMobile = NARROW || SHORT;
      this.startScreenSaverCheck();
    },
    openBlock(block) {
      this.block = block;
      this.dialog = true;
      if (block.type === "video") {
        setTimeout(() => {
          this.$hub.$emit("playMedia", block);
        }, 510);
      }
    },
    closeContact() {
      this.showContact = false;
    },
    showPdfPopup(block) {
      if (block) {
        this.slideshowPopup.block = block;
        this.slideshowPopup.open = true;
      } else {
        this.slideshowPopup.block = null;
        this.slideshowPopup.open = false;
      }
    },
    async submitContact(data) {
      this.contentDisabled = true;
      try {
        await $http.post(MAIL_URL, data);
      } catch (e) {} // eslint-disable-line no-empty
    },
    showScreenSaver() {
      this.$hub.$emit("pauseAllMedia");
      this.slideshowPopup.open = false;
      this.screenSaver = true;
    },
    hideScreenSaver() {
      this.screenSaver = false;
      setTimeout(this.startScreenSaverCheck, 50);
    },
    start() {
      this.init = true;
      this.setWidth();
      // this.startScreenSaverCheck();
    },

    /**
     * Initializes the QR mode based on URL parameters or local storage.
     *
     * This method checks for a "booth" parameter in the URL's query string. If not
     * found there, it checks the hash part of the URL. It then sets the `qrMode`
     * property accordingly and stores/removes this value in/from localStorage based
     * on its state. If the "booth" parameter is absent from both the query string
     * and the hash, the method retrieves the state from localStorage, defaulting to
     * false if not present. Finally, it logs the current state of the booth mode to
     * the console.
     *
     * @method
     */

    initQrMode() {
      // Create a URLSearchParams object from the query string of the URL
      const searchParams = new URLSearchParams(window.location.search);

      // If the "booth" parameter is not found in the query string, check the hash part of the URL
      if (!searchParams.has("booth") && window.location.hash.includes("?")) {
        // Extract parameters from the hash part of the URL and append them to searchParams
        searchParams.append(
          ...new URLSearchParams(window.location.hash.split("?")[1]).entries()
        );
      }

      // Get the value of the "booth" parameter
      const boothValue = searchParams.get("booth");

      // If the "booth" parameter is found, set qrMode based on its value
      if (boothValue !== null) {
        this.qrMode = boothValue === "true";
        // Save the state of qrMode to localStorage if true, otherwise remove it
        if (this.qrMode) {
          localStorage.setItem("boothMode", this.qrMode);
        } else {
          localStorage.removeItem("boothMode");
        }
      } else {
        // If the "booth" parameter is not found, retrieve the state from localStorage
        // If it's not in localStorage, default to false
        this.qrMode = localStorage.getItem("boothMode") === "true";
      }

      // Log the state of booth mode for debugging purposes
      console.log("booth mode", this.qrMode ? "on" : "off");
    },
    extLink(url, img, text, alwaysNewTab = false) {
      console.log("extLink", url, img);
      if (this.qrMode && !alwaysNewTab) {
        this.qrCode = "/images/qr/" + img;
        this.qrText = text;
        this.showQr = true;
      } else {
        window.open(url, "_blank");
      }
    },
    closeQr() {
      this.showQr = false;
    }
  },
  created() {
    this.$hub.$on("openBlock", this.openBlock);
    this.$hub.$on("requestPopup", this.openRequestPopup);
    this.$hub.$on("submitContact", this.submitContact);
    this.$hub.$on("closeContact", this.closeContact);
    this.$hub.$on("showPdfPopup", this.showPdfPopup);
    this.$hub.$on("startScreenSaverCheck", this.startScreenSaverCheck);
    window.addEventListener("resize", this.setWidth);
  },
  mounted() {
    window.$app = this;
    this.initQrMode();
    this.start();
    this.analytics.init();
    this.analytics.page("menu");
    this.setWidth();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setWidth);
    this.$hub.$off("openBlock");
    this.$hub.$off("requestPopup");
    this.$hub.$off("submitContact");
    this.$hub.$off("closeContact");
    this.$hub.$off("showPdfPopup");
    this.$hub.$off("startScreenSaverCheck");
  }
};
</script>

<style lang="scss">
@font-face {
  font-family: "Karbon";
  src: local("Karbon"), url(./fonts/Karbon/karbon-regular.woff) format("woff");
}
@font-face {
  font-family: "Karbon-Medium";
  src: local("Karbon-Medium"),
    url(./fonts/Karbon/karbon-medium.woff) format("woff");
}
@font-face {
  font-family: "Karbon-Bold";
  src: local("Karbon-Bold"), url(./fonts/Karbon/karbon-bold.woff) format("woff");
}
@font-face {
  font-family: "Karbon-SemiBold";
  src: local("Karbon-SemiBold"),
    url(./fonts/Karbon/karbon-semibold.woff) format("woff");
}
@font-face {
  font-family: "Johnson-Medium";
  src: url(./fonts/JohnsonDisplay-Medium.woff) format("woff");
}
@font-face {
  font-family: "JohnsonText-Medium";
  src: url(./fonts/JohnsonText-Medium.woff) format("woff");
}
html,
body,
#app {
  font-family: Karbon-Bold, Verdana, Geneva, sans-serif;
  overflow-y: auto !important;
}
#dialog .container {
  max-width: inherit !important;
}
.v-dialog {
  width: unset !important;
}
.v-dialog > .v-card > .v-card__title.dialog-title {
  color: white !important;
}
.v-dialog > .v-card > .v-card__title.dialog-title-mobile {
  font-size: 18px !important;
}
.v-dialog > #dialog.contact-dialog.v-card > .v-card__text.dialog-text {
  height: inherit;
  overflow-y: auto;
}
.v-dialog > .v-card > .v-card__text.dialog-text {
  max-height: 80vh;
  height: 80vh;
  overflow-y: auto;
}
.v-dialog > .v-card > .v-card__text.dialog-text-mobile {
  padding: 0 6px 20px;
}
#requestPopup {
  max-width: 800px !important;
  width: 90vw !important;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: initial !important;
  overflow-y: initial !important;
}
#download-qr,
.download-qr {
  text-align: center;
  font-size: 1rem;
  font-family: "Roboto", sans-serif !important;
  line-height: 120%;
  div {
    margin-top: 1em;
  }
}
.request-btn {
  margin: 0 auto 1em !important;
  text-transform: initial !important;
  width: 80%;
}
@media only screen and (max-width: 420px) {
  .request-btn {
    height: 50px !important;
    width: 100% !important;
    span {
      width: 70vw !important;
      white-space: normal !important;
    }
  }
}
#screen-saver {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(34, 34, 34, 0.8);
  //background-color: #222222;
  z-index: 10000;
  color: white;
}

.ss-prompt-wrapper {
  position: absolute;
  z-index: 10000000000;
  width: 100%;
  top: 30px;
  text-align: center;
  .ss-prompt {
    user-select: none;
    cursor: pointer;
    background-color: #222222;
    display: inline-block;
    color: white;
    text-transform: uppercase;
    padding: 5px 10px;
  }
}

.init-prompt-wrapper {
  position: absolute;
  z-index: 10000000000;
  width: 100%;
  top: 40vh;
  text-align: center;
  .init-prompt {
    user-select: none;
    cursor: pointer;
    color: #222222;
    font-size: 24px;
    display: inline-block;
    background-color: #dddddd;
    text-transform: uppercase;
    padding: 10px 20px;
    box-shadow: 1px 1px 2px #000000cc;
  }
}
#above-footer {
  width: 100%;
  padding: 0 12px;
}
.disclaimer2 {
  width: 100%;
  padding: 0 12px;
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  font-family: "Roboto", sans-serif !important;
  line-height: 120%;
}
.v-application--wrap {
  overflow-y: hidden;
}
.hidden {
  visibility: hidden;
  width: 0;
  height: 0;
}
.qr-mode {
  .non-qr {
    display: none !important;
  }
  .qr {
    display: block !important;
    div.download-qr {
      font-size: 0.85rem;
    }
    img {
      max-width: 128px;
    }
  }
}
.non-qr-mode {
  .non-qr {
    display: block !important;
  }
  .qr {
    display: none !important;
  }
}
.download-qr {
  img {
    max-width: 256px;
  }
}
</style>
